<template>
  <div :class="$style.container">
    <div :class="$style.buttonWraper">
      <el-button
        @click="$router.push('prices-policy/create')"
        :class="$style.button"
        type="primary"
        width="150"
        >Создать</el-button
      >
    </div>
    <el-table :data="pricesPolicies" stripe>
      <el-table-column label="Название">
        <template slot-scope="scope">
          <el-input v-model="scope.row.name"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Название цены в МС">
        <template slot-scope="scope">
          <el-input v-model="scope.row.moyskladPriceName"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Название количества в МС">
        <template slot-scope="scope">
          <el-input v-model="scope.row.moyskladQuantityName"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        :class-name="$style.tableCellCenter"
        width="150"
        label="По умолчанию"
      >
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.isDefault"
            :class="[
              $style.checkBox,
              { [$style.isDefault]: scope.row.isDefault }
            ]"
            @change="changeCheckBox($event, scope.row.id)"
          />
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template slot-scope="scope">
          <el-button plain type="primary" @click="updatePricePolicy(scope.row)">
            Сохранить
          </el-button>
          <el-button
            plain
            type="danger"
            @click="deletePricePolicy(scope.row)"
            icon="el-icon-delete"
            circle
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="total / limit > 1"
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getPricesPolicies"
      background
    />
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
export default {
  mixins: [notifications],
  mounted() {
    this.getPricesPolicies()
  },
  data() {
    return {
      pricesPolicies: [],
      page: 1,
      total: 0,
      limit: 20
    }
  },
  methods: {
    async getPricesPolicies() {
      const loading = this.$loading({
        lock: true
      })
      const {
        value,
        error
      } = await delivery.AddwineCore.PricePolicyActions.getList({
        limit: this.limit,
        page: this.page
      })
      loading.close()
      if (!error) {
        this.pricesPolicies = value.data
        this.total = value.meta.count
      }
    },
    async deletePricePolicy(pricePolicy) {
      if (
        confirm(
          `Вы действительно хотите удалить ценовую политику ${pricePolicy.name} ?`
        )
      ) {
        const loading = this.$loading({
          lock: true
        })
        const result = await delivery.AddwineCore.PricePolicyActions.delete(
          pricePolicy.id
        )
        loading.close()
        if (!result.error) {
          this.showNotification('Ценовая политика успешно удалена', 'success')
          this.getPricesPolicies()
        }
      }
    },
    async updatePricePolicy(pricePolicy) {
      const loading = this.$loading({
        lock: true
      })
      const result = await delivery.AddwineCore.PricePolicyActions.update(
        pricePolicy.id,
        {
          moyskladPriceName: pricePolicy.moyskladPriceName,
          moyskladQuantityName: pricePolicy.moyskladQuantityName,
          name: pricePolicy.name,
          isDefault: pricePolicy.isDefault
        }
      )
      loading.close()
      if (!result.error) {
        this.showNotification(
          'Ценовая политика успешно отредактирована',
          'success'
        )
        this.getPricesPolicies()
      }
    },
    changeCheckBox(value, id) {
      this.pricesPolicies.forEach((pricePolicy) => {
        if (pricePolicy.id !== id) {
          pricePolicy.isDefault = false
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;

  .isDefault {
    pointer-events: none;
  }

  .item {
    display: flex;
    flex-direction: column;
  }
  .tableCellCenter {
    text-align: center;
  }

  .buttonWraper {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
    .button {
      width: 9rem;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pagination {
    @include pagination;
  }
}
</style>
